.carouselContainer {
  @apply text-voca-purple;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-end;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.carouselItem {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s, transform 1s;
  transform: translateY(50%);
  white-space: nowrap;
}

.carouselItem.previous {
  animation: slideOut 0.25s ease-in forwards;
}

.carouselItem.active {
  animation: slideIn 0.25s ease-out forwards;
  animation-delay: 0.125s;
}

.carouselItem.disabled {
  opacity: 0;
  transform: translateY(50%);
}

@keyframes slideIn {
  0% {
    opacity: 1;
    transform: translateY(35%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  50% {
    opacity: 0;
    transform: translateY(-25%);
  }
  100% {
    opacity: 0;
    transform: translateY(-50%);
  }
}
