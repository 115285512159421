.navContainer {
  @apply sticky top-0 flex w-full flex-row justify-center pt-2 pb-2;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 999;
  background-color: white;
}

.nav {
  @apply flex w-full max-w-[75rem] scale-90 flex-row justify-between md:scale-100 md:px-4;
  gap: 10px;
}

.buttonContainer {
  @apply flex items-end space-x-[2];
  gap: 1rem;
}
