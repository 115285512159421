.container {
  @apply flex h-full flex-col items-center justify-start overflow-y-scroll scroll-smooth;
}

.section {
  @apply relative flex w-full flex-shrink-0 flex-col items-center justify-center
  bg-white px-7 pt-6 pb-10 sm:px-16 sm:py-16 md:px-32 lg:px-52 xl:px-64;
}
.section1 {
  @extend .section;
  @apply pt-2 sm:max-h-[50rem];
  .nav {
    @apply flex w-full max-w-[75rem] flex-row justify-between;
  }
  .content {
    @apply mt-10 mb-10 flex h-full w-full max-w-[75rem] flex-col items-start justify-between sm:mb-14 sm:mt-20;
  }
  .tagline {
    @extend .header;

    &Container {
      @apply flex w-full flex-col items-start justify-start text-start;
    }
  }
  &Gradient1 {
    @apply left-0;
    background: radial-gradient(
        ellipse at left bottom,
        rgb(142, 53, 215) 0%,
        rgb(76, 53, 215) 35%,
        transparent 70%
      )
      no-repeat bottom;
  }
  &Gradient2 {
    @apply right-0;
    background: radial-gradient(
        ellipse at right bottom,
        rgb(142, 53, 215) 0%,
        rgb(76, 53, 215) 35%,
        transparent 70%
      )
      no-repeat bottom;
  }
  &Gradient {
    @apply absolute bottom-0 h-full w-[70%] sm:w-[55%];
    background-size: 100% 25%;
  }
  @screen sm {
    &Gradient {
      background-size: 100% 40%;
    }
  }
}
.section2 {
  @extend .section;
  @apply bg-neutral-800;
  .content {
    @apply mb-10 flex h-full w-full max-w-[75rem] flex-col items-center justify-between sm:flex-row;
    .text {
      @apply mb-10 w-full font-[300] text-white sm:m-0 sm:text-lg md:text-xl;
    }
    .buttons {
      @apply flex h-full w-full flex-col justify-start sm:ml-5 lg:flex-row;
    }
  }
}
.section3 {
  @extend .section;
  @apply pb-0;
  .title {
    @extend .header;
    @apply text-center md:mb-12 md:mt-10;
  }
  .features {
    @apply mb-2 flex w-full flex-row flex-wrap items-baseline justify-center;
    .feature {
      @apply mb-10 flex w-full w-[18.75rem] w-[25rem] flex-col items-center justify-center px-5 text-center;
      .icon {
        @apply mb-4 h-24 w-24 text-voca-purple;
      }
      .title {
        @apply mb-2 font-rubik text-xl font-[500] md:text-2xl xl:text-3xl;
      }
      .description {
        @apply text-sm font-[300] md:text-base;
      }
    }
  }
}
.headerButton {
  @apply flex h-10 flex-row items-center justify-center rounded-lg bg-voca-purple px-4 py-2 text-white;
}
.button {
  @apply flex h-10 cursor-pointer flex-row items-center justify-center rounded-lg bg-neutral-800 px-4 py-2 text-white transition-colors duration-200 hover:bg-neutral-900;
  .black {
    @apply bg-neutral-800;
  }
  .white {
    @apply bg-white;
  }
  .purple {
    @apply bg-voca-purple;
  }
  &Icon {
    @apply mr-2 h-6 w-6;
  }
}
.header {
  @apply w-full whitespace-nowrap font-neco text-3xl font-[500] leading-10 sm:text-5xl sm:leading-[3rem] md:text-5xl md:leading-[4rem] lg:text-6xl lg:leading-[6rem] xl:text-7xl xl:leading-[7rem];
  font-weight: bold;
}
@media (max-width: 375px) {
  .header {
    @apply text-2xl;
  }
}
.video {
  @apply flex aspect-[15.4/10] w-full flex-shrink-0 flex-row items-center justify-center overflow-hidden rounded-xl;
}

.section4 {
  @extend .section;
  @apply m-0 px-0 pt-0 pb-0;
}

.footer {
  @apply flex w-full flex-col items-center justify-center;
  background-color: #4d34d7;
}

.footerContent {
  @apply pt-4 text-center text-sm font-[300] text-white;
  line-height: 2;
  padding-bottom: clamp(5vmin, 160px, 12vmin);
}

.logoWallWrapper {
  @apply pt-4 text-center text-gray-500;
}

.heart {
  color: #e90606;
  display: inline-block;
  animation: pound 0.35s infinite alternate;
}

@keyframes pound {
  to {
    transform: scale(1.1);
  }
}
