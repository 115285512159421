.root {
  --color-text: rgb(88, 41, 158);
  --color-bg: #ffffff;
  --color-bg-accent: #ffffff;
  --size: clamp(1rem, 20vw, 10rem);
  --gap: calc(var(--size) / 14);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
  width: 100%;
}

.root * {
  box-sizing: border-box;
  border: 0 transparent;
}

article.wrapper {
  @apply min-h-[200px] md:min-h-[280px] md:pb-4;
  align-content: center;
  overflow: hidden;
  gap: 10px var(--gap);
  width: 100%;
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--color-text);
  background-color: var(--color-bg);
}

.marquee {
  @apply h-[100px] md:h-[140px];
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 10px var(--gap);
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px var(--gap);
  min-width: 100%;
  animation: scroll-x var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee__reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(var(--scroll-start));
  }
  to {
    transform: translateY(var(--scroll-end));
  }
}

/* Element styles */
.marquee svg,
.marquee img {
  display: grid;
  place-items: center;
  max-height: 100%;
  max-width: var(--size);
  filter: grayscale(1);
  /* aspect-ratio: 16/9; */
  padding: calc(var(--size) / 10);
  border-radius: 0.5rem;
}

/* Parent wrapper */
.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: auto;
  max-width: 100vw;
}

@keyframes fade {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
